.otp {
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__back {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 8px;
    font-weight: 500;
    cursor: pointer;
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__link {
    color: #593E95;
    cursor: pointer;
    padding: 6px 0;
    display: inline-flex;
    align-items: center;
    gap: 8px;

    &_disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}