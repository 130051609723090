.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.fieldsContainer {
  display: flex;
  flex-wrap: wrap;
  //gap: 8px;
  //&__item {
  //  flex-shrink: 0;
  //  flex: 1;
  //}
}

.input {
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.18px;
  text-align: center;
}

.codeError {
  width: 100%;
  color: #c41c1c;
}
